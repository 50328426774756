<template>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleClose"
    destroy-on-close
    title="调整单详情"
    size="1111px"
    :track="{
      name_zh: `在库管理/库存调整/抽屉-${isExamine? '审核': '详情'}`,
      trackName: $route.path
    }"
  >
    <pl-block>
      <basic-information :form-data="formData" />
    </pl-block>
    <pl-block>
      <el-table
        v-loading="loading.init"
        :data="tableData"
        border
        :span-method="arraySpanMethod"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
        />
        <el-table-column
          v-for="(item,index) in TABLECOLUMN(formData.adjustType)"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.minWidth"
          :width="item.width"
        >
          <template #default="scope">
            <span v-if="item.prop === 'batchAttributes' || item.prop === 'newBatchAttributes' ">
              <p
                v-for="(batchField) in attributeCodes"
                :key="batchField.id"
                class="batch-field"
              >
                <span v-if="judgeBatchField(batchField.fieldCode,scope.row[item.prop])">
                  {{ batchField.fieldName }}:
                  {{ formatBatchField(batchField.fieldCode,scope.row[item.prop]) }}
                </span>
              </p>
            </span>
            <span v-else-if="item.prop === 'goodsQuality' || item.prop === 'newGoodsQuality'">
              {{ filterLabel(scope.row[item.prop],GOODS_QUALITY) }}
            </span>
            <span v-else-if="item.prop === 'createTime'">
              {{ item.formatter(scope.row,item.prop) }}
            </span>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </pl-block>
    <pl-block v-if="isExamine">
      <el-form
        ref="form"
        :model="form"
      >
        <el-form-item label="审核备注：">
          <el-input
            v-model="form.remark"
            type="textarea"
            placeholder="驳回时备注必须填写"
          />
        </el-form-item>
        <el-form-item class="button-box">
          <el-button
            type="primary"
            :loading="loading.onSubmit"
            @click="onSubmit(ADJUSTRESULT.PASS)"
          >
            通过
          </el-button>
          <el-button :loading="loading.onSubmit" @click="onSubmit(ADJUSTRESULT.REJECT)">
            驳回
          </el-button>
        </el-form-item>
      </el-form>
    </pl-block>
  </nh-drawer>
</template>

<script>
import loadingMixin from '@thales/loading';
import BasicInformation from '../components/BasicInformation.vue';
import { TABLECOLUMN, ADJUSTRESULT } from './fileds';
import { stockAdjustDetail, stockAdjustAudit, batchFieldList } from '../api';
import { ADJUSTMENT_TYPE } from '../constant';

export default {
  name: 'InventoryAdjustmentDetails',
  components: {
    BasicInformation,
  },
  mixins: [loadingMixin],
  emits: ['onSuccess'],
  data() {
    return {
      drawerVisible: false,
      TABLECOLUMN,
      ADJUSTMENT_TYPE,
      ADJUSTRESULT,
      adjustId: null,
      tableData: [],
      loading: {
        init: false,
      },
      type: 2,
      form: {},
      isExamine: false,
      formData: {},
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
  },
  methods: {
    open(id, isExamine) {
      this.adjustId = id;
      this.isExamine = isExamine || false;
      this.init();
      this.loadBatchFieldList();
      this.drawerVisible = true;
    },
    async init() {
      this.formData = await stockAdjustDetail({ adjustId: this.adjustId });
      this.tableData = [];
      this.formData.stockDetails.forEach((item, index) => {
        this.tableData.push(...item.newStocks.map((stockItem) => ({
          ...this.formData.stockDetails[index].oldStock,
          newBatchAttributes: stockItem.batchAttributes,
          ...stockItem.adjustAttribute,
        })));
      });
    },
    async loadBatchFieldList() {
      let result = await batchFieldList({});
      result = result || [];
      this.attributeCodes = result.map((item) => ({
        ...item,
        label: item.fieldName,
        value: item.fieldCode,
      }));
    },
    async onSubmit(opinion) {
      if (opinion === this.ADJUSTRESULT.REJECT && !this.form.remark) {
        this.$message({ type: 'warning', message: '请填写驳回原因' });
        return;
      }
      const data = {
        adjustId: this.adjustId,
        adjustResult: opinion,
        remark: this.form.remark,
      };
      await stockAdjustAudit(data);
      this.$message({ type: 'success', message: '操作成功' });
      this.$emit('onSuccess');
      this.handleClose();
    },
    getStocksNum(arr, num) {
      return arr.findIndex((item) => {
        const [min, max] = item;
        return min <= num && num < max;
      });
    },
    arraySpanMethod({ rowIndex, columnIndex }) {
      const arrNewStocksLength = this.formData.stockDetails.reduce((prev, curr) => ({
        extent: [...prev.extent, [prev.total, prev.total + curr.newStocks.length]],
        total: prev.total + curr.newStocks.length,
      }), { extent: [], total: 0 }).extent;
      const curr = this.getStocksNum(arrNewStocksLength, rowIndex);
      const [firstCurr] = arrNewStocksLength[curr];
      if (this.formData.adjustType === this.ADJUSTMENT_TYPE[1].value) {
        if (columnIndex >= 0 && columnIndex < 8) {
          if (firstCurr === rowIndex) {
            return [this.formData.stockDetails[curr].newStocks.length, 1];
          }
          return [0, 0];
        }
      } else if (this.formData.adjustType === this.ADJUSTMENT_TYPE[2].value) {
        if (columnIndex >= 0 && columnIndex < 12) {
          if (firstCurr === rowIndex) {
            return [this.formData.stockDetails[curr].newStocks.length, 1];
          }
          return [0, 0];
        }
      } else if (this.formData.adjustType === this.ADJUSTMENT_TYPE[3].value) {
        if (columnIndex >= 0 && columnIndex < 10) {
          if (firstCurr === rowIndex) {
            return [this.formData.stockDetails[curr].newStocks.length, 1];
          }
          return [0, 0];
        }
      } else if (columnIndex >= 0 && columnIndex < 11) {
        if (firstCurr === rowIndex) {
          return [this.formData.stockDetails[curr].newStocks.length, 1];
        }
        return [0, 0];
      }
      return [1, 1];
    },
    filterLabel(val, arr) {
      const filertArr = arr && arr.find((item) => item.value === val);
      return filertArr && filertArr.label;
    },
    formatBatchField(code, batchAttributes) {
      return batchAttributes ? batchAttributes[code] : '';
    },
    judgeBatchField(code, batchAttributes) {
      return batchAttributes && (batchAttributes[code] || batchAttributes[code] === '');
    },
    handleClose() {
      this.adjustId = null;
      this.tableData = [];
      this.form = {};
      this.formData = {};
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.button-box {
  text-align: right;
}
</style>
