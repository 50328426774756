<template>
  <pl-block>
    <PlForm
      v-model="searchQuery"
      mode="search"
      :fields="serchFields"
      @submit="handleQuery"
      @reset="handleQuery"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
      @selection-change="handleSelectionChange"
      @update:pagination="handlePaginationChange"
    >
      <template #tableHeaderRight>
        <nh-button
          :loading="loading.handleExport"
          :track="{
            trackName: '在库管理/库存调整/[导出]'
          }"
          @click="handleExport"
        >
          导出
        </nh-button>
      </template>
      <template #auditRemark="{row}">
        <el-tooltip
          :content="row.auditRemark"
        >
          <span class="auditRemark-box">{{ row.auditRemark }}</span>
        </el-tooltip>
      </template>
      <template #adjustOrderNo="{row}">
        <nh-link
          :to="{}"
          :text="row.adjustOrderNo"
          @click="handleDetails(row)"
        />
      </template>
      <template #action="{row}">
        <nh-button
          v-if="row.adjustStatus === DOCUMENT_STATUS[1].value"
          type="text"
          @click="jump(row,isExamine)"
        >
          审核
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <InventoryDetailDIalig ref="inventoryDetails" @onSuccess="getTableData" />
</template>
<script>
import loadingMixin from '@thales/loading';
import { SearchType } from '@/constant/form';
import InLibrary from '@/constant/pageNames/inLibrary';
import {
  TABLECOLUMN, serchFields,
} from './fileds';
import { DOCUMENT_STATUS } from './constant';
import {
  stockAdjustPage, stockAdjustExport,
} from './api';
import InventoryDetailDIalig from './inventory-adjustment-details/index.vue';

const isExamine = true;
export default {
  name: InLibrary.INVENTORY_ADJUSTMENT,
  components: { InventoryDetailDIalig },
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      SearchType,
      isExamine,
      DOCUMENT_STATUS,
      tableData: [],
      searchQuery: {
      },
      loading: {
        getTableData: false,
        handleExport: false,
      },
      selectIds: [],
    };
  },
  activated() {
    this.getTableData();
  },
  methods: {
    formatBatchField(code, batchAttributes) {
      return batchAttributes ? batchAttributes[code] : '';
    },
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      this.selectIds = [];
      const params = this.buildParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await stockAdjustPage(pagination, params);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    handleSelectionChange(selection) {
      this.selectIds = selection.map((item) => item.id);
    },
    buildParams() {
      const [createTimeStart, createTimeEnd] = this.searchQuery.createTime || [];
      const [auditTimeStart, auditTimeEnd] = this.searchQuery.auditTime || [];
      return {
        ...this.searchQuery,
        createTimeStart,
        createTimeEnd,
        auditTimeStart,
        auditTimeEnd,
      };
    },
    handlePaginationChange() {
      this.getTableData();
    },
    async handleExport() {
      const data = this.buildParams();
      if (this.selectIds.length > 0) {
        data.ids = this.selectIds;
      }
      await stockAdjustExport(data);
    },
    jump({ id }, examine) {
      this.$refs.inventoryDetails.open(id, examine);
    },
    handleDetails({ id }) {
      this.$refs.inventoryDetails.open(id);
    },
  },
};
</script>
<style lang="scss" scoped>
.batch-field {
  text-indent: 12px;
  padding: 4px 0;
}

.auditRemark-box {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

:deep(.el-date-editor.el-input,
.el-date-editor.el-input__inner) {
  width: 100%;
}
</style>
