import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import {
  DOCUMENT_STATUS, ADJUSTMENT_SOURCE, ADJUSTMENT_TYPE,
} from './constant';

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}
export const TABLECOLUMN = [{
  type: 'selection',
  width: 50,
}, {
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '调整单号',
  prop: 'adjustOrderNo',
  minWidth: 180,
}, {
  label: '单据状态',
  prop: 'adjustStatus',
  minWidth: 100,
  formatter: (row) => filterLabel(row.adjustStatus, DOCUMENT_STATUS),
}, {
  label: '调整来源',
  prop: 'sourceType',
  minWidth: 120,
  formatter: (row) => filterLabel(row.sourceType, ADJUSTMENT_SOURCE),
}, {
  label: '调整类型',
  prop: 'adjustType',
  minWidth: 100,
  formatter: (row) => filterLabel(row.adjustType, ADJUSTMENT_TYPE),
}, {
  label: '来源单据号',
  prop: 'sourceOrderNo',
  minWidth: 100,
}, {
  label: '创建人',
  prop: 'createUser',
  minWidth: 130,
}, {
  label: '创建时间',
  prop: 'createTime',
  ...DateTime,
}, {
  label: '审核人',
  prop: 'auditUser',
  minWidth: 130,
}, {
  label: '审核时间',
  prop: 'auditTime',
  ...DateTime,
}, {
  label: '审核备注',
  prop: 'auditRemark',
  minWidth: 120,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 120,
},
];
export const serchFields = [{
  label: '调整单号',
  prop: 'adjustOrderNo',
  component: FormType.INPUT,
}, {
  label: '单据状态',
  prop: 'adjustStatus',
  component: FormType.SELECT,
  options: DOCUMENT_STATUS,
}, {
  label: '调整来源',
  prop: 'sourceType',
  component: FormType.SELECT,
  options: ADJUSTMENT_SOURCE,
}, {
  label: '单据来源号',
  prop: 'sourceOrderNo',
  component: FormType.INPUT,
}, {
  label: '调整类型',
  prop: 'adjustType',
  component: FormType.SELECT,
  options: ADJUSTMENT_TYPE,
}, {
  label: '创建时间',
  prop: 'createTime',
  component: FormType.DATE_PICKER,
  componentAttrs: {
    type: 'daterange',
    default: [],
  },
}, {
  label: '审核时间',
  prop: 'auditTime',
  component: FormType.DATE_PICKER,
  componentAttrs: {
    type: 'daterange',
    default: [],
  },
},
];
