<template>
  <div class="details-page">
    <el-row :gutter="20">
      <el-col
        v-for="(item, index) in BasicInfo"
        :key="index"
        :span="6"
      >
        <el-form label-width="100px" label-position="left">
          <el-form-item :label="item.label">
            <span v-if="item.ENUM">{{ filterLabel(formData[item.prop],item.ENUM) }}</span>
            <span v-else-if="item.time">{{ Moment.format(formData[item.prop]) }}</span>
            <span v-else>{{ formData[item.prop] }}</span>
            <!-- {{ item.ENUM?filterLabel(formData[item.prop],item.ENUM):formData[item.prop] }} -->
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Moment from '@/utils/moment';
import {
  DOCUMENT_STATUS, ADJUSTMENT_SOURCE, ADJUSTMENT_TYPE,
} from '../constant';

export default {
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      Moment,
      DOCUMENT_STATUS,
      ADJUSTMENT_SOURCE,
      ADJUSTMENT_TYPE,
      BasicInfo: [
        {
          label: '调整单号:',
          prop: 'adjustOrderNo',
        },
        {
          label: '单据状态:',
          prop: 'adjustStatus',
          ENUM: DOCUMENT_STATUS,
        },
        {
          label: '调整来源:',
          prop: 'sourceType',
          ENUM: ADJUSTMENT_SOURCE,
        },
        {
          label: '调整类型:',
          prop: 'adjustType',
          ENUM: ADJUSTMENT_TYPE,
        },
        {
          label: '来源单据号:',
          prop: 'sourceOrderNo',
        },
        {
          label: '创建人:',
          prop: 'createUser',
        },
        {
          label: '创建时间:',
          prop: 'createTime',
          time: true,
        },
        {
          label: '审核人:',
          prop: 'auditUser',
        },
        {
          label: '审核时间:',
          prop: 'auditTime',
          time: true,
        },
      ],
    };
  },
  methods: {
    filterLabel(val, arr) {
      const filterArr = arr.find((item) => item.value === val);
      return filterArr ? filterArr.label : '';
    },
  },
};
</script>
<style lang="scss" scoped>
.details-page {
  padding: 30px;
}

</style>
