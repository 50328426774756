import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:库存调整单分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15839
 */
export function stockAdjustPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/adjust_order/page', data, {
    params,
  });
}

/**
 * @description:库存调整单导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15843
 */
export function stockAdjustExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/adjust_order/export', data, { fileName: '库存调整单', extension: 'xlsx' });
}
/**
 * @description:库存调整单详情
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15841
 */
export function stockAdjustDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/adjust_order/detail', data);
}
/**
 * @description:库存调整单审核
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15845
 */
export function stockAdjustAudit(data) {
  return wmsPlusHttp.post('/warehouse_management_system/adjust_order/audit', data);
}

/**
 * @description: 批次字段列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/385/interface/api/12622
 *
 */
export function batchFieldList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/batch_field/list', data, {});
}
