export const DOCUMENT_STATUS = [
  {
    value: 'CANCELED',
    label: '已驳回',
  },
  {
    value: 'PRE_AUDIT',
    label: '待审核',
  },
  {
    value: 'AUDITED',
    label: '已审核',
  },
];

export const ADJUSTMENT_SOURCE = [
  {
    value: 'INVENTORY',
    label: '盘点单',
  },
  {
    value: 'MANUAL_ADD',
    label: '手动新增',
  },
];

export const ADJUSTMENT_TYPE = [
  {
    value: 'INVENTORY_ADJUST',
    label: '盘点调整',
  },
  {
    value: 'AMOUNT_ADJUST',
    label: '数量调整',
  },
  {
    value: 'BATCH_NO_ADJUST',
    label: '批次调整',
  },
  {
    value: 'OTHER_ADJUST',
    label: '其他调整',
  },
];
