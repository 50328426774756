import Moment from '@/utils/moment';
// import { DateTime } from '@/constant/tableConfig';
import {
  ADJUSTMENT_TYPE,
} from '../constant';

const BASICS_TABLECOLUMN = [{
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  minWidth: 180,
}, {
  label: '货品名称',
  prop: 'goodsName',
  minWidth: 120,
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 180,
}, {
  label: '温层',
  prop: 'temperatureLayerName',
  minWidth: 120,
}, {
  label: '库位',
  prop: 'locationCode',
  minWidth: 100,
}, {
  label: '货品质量',
  prop: 'goodsQuality',
  minWidth: 100,
}];

const NUM_TABLECOLUMN = [
  ...BASICS_TABLECOLUMN,
  {
    label: '库存数量',
    prop: 'stockAmountStr',
    minWidth: 130,
  }, {
    label: '辅助数量',
    prop: 'auxiliaryAmountStr',
    minWidth: 130,
  }, {
    label: '调整量',
    prop: 'stockDiffAmountStr',
    minWidth: 130,
  }, {
    label: '辅助数量调整量',
    prop: 'auxiliaryDiffAmountStr',
    minWidth: 120,
  }, {
    label: '调整人',
    prop: 'createUser',
    minWidth: 120,
  }, {
    label: '调整时间',
    prop: 'createTime',
    minWidth: 180,
    formatter: (row) => Moment.format(row.createTime),
  },
];

const BATCH_TABLECOLUMN = [
  ...BASICS_TABLECOLUMN,
  {
    label: '原批次号',
    prop: 'batchNo',
    minWidth: 130,
  }, {
    label: '批属性',
    prop: 'batchAttributes',
    minWidth: 130,
  }, {
    label: '原库存数量',
    prop: 'stockAmountStr',
    minWidth: 130,
  }, {
    label: '辅助数量',
    prop: 'auxiliaryAmountStr',
    minWidth: 130,
  }, {
    label: '原容器号',
    prop: 'containerCode',
    minWidth: 130,
  }, {
    label: '目标批属性',
    prop: 'newBatchAttributes',
    minWidth: 120,
  }, {
    label: '目标批次号',
    prop: 'newBatchNo',
    minWidth: 120,
  }, {
    label: '库存量(调整)',
    prop: 'stockDiffAmountStr',
    minWidth: 120,
  }, {
    label: '辅助数量(调整)',
    prop: 'auxiliaryDiffAmountStr',
    minWidth: 120,
  }, {
    label: '容器号(调整)',
    prop: 'newContainerCode',
    minWidth: 120,
  }, {
    label: '调整人',
    prop: 'createUser',
    minWidth: 120,
  }, {
    label: '调整时间',
    prop: 'createTime',
    minWidth: 180,
    formatter: (row) => Moment.format(row.createTime),
  },
];

const OTHER_TABLECOLUMN = [
  ...BASICS_TABLECOLUMN,
  {
    label: '原批次号',
    prop: 'batchNo',
    minWidth: 130,
  }, {
    label: '原库存数量',
    prop: 'stockAmountStr',
    minWidth: 130,
  }, {
    label: '原容器号',
    prop: 'containerCode',
    minWidth: 130,
  }, {
    label: '货品质量(调整)',
    prop: 'newGoodsQuality',
    minWidth: 100,
  }, {
    label: '库存量(调整)',
    prop: 'stockDiffAmountStr',
    minWidth: 120,
  }, {
    label: '容器号(调整)',
    prop: 'newContainerCode',
    minWidth: 120,
  }, {
    label: '调整人',
    prop: 'createUser',
    minWidth: 120,
  }, {
    label: '调整时间',
    prop: 'createTime',
    minWidth: 180,
    formatter: (row) => Moment.format(row.createTime),
  },
];

const INVENTORY_TABLECOLUMN = [
  ...BASICS_TABLECOLUMN,
  {
    label: '批次号',
    prop: 'batchNo',
    minWidth: 130,
  }, {
    label: '批属性',
    prop: 'batchAttributes',
    minWidth: 130,
  }, {
    label: '库存数量',
    prop: 'stockAmountStr',
    minWidth: 130,
  }, {
    label: '容器号',
    prop: 'containerCode',
    minWidth: 130,
  }, {
    label: '调整库存量',
    prop: 'stockDiffAmountStr',
    minWidth: 120,
  }, {
    label: '调整人',
    prop: 'createUser',
    minWidth: 120,
  }, {
    label: '调整时间',
    prop: 'createTime',
    minWidth: 180,
    formatter: (row) => Moment.format(row.createTime),
  },
];
export function TABLECOLUMN(type) {
  switch (type) {
    case ADJUSTMENT_TYPE[1].value:
      return NUM_TABLECOLUMN;
    case ADJUSTMENT_TYPE[2].value:
      return BATCH_TABLECOLUMN;
    case ADJUSTMENT_TYPE[0].value:
      return INVENTORY_TABLECOLUMN;
    default:
      return OTHER_TABLECOLUMN;
  }
}

export const ADJUSTRESULT = {
  PASS: 'PASS', // 通过
  REJECT: 'REJECT', // 驳回
};
